import { Identify, identify } from '@amplitude/analytics-browser';
import { useGetUserData } from 'hooks/use-get-user-data';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isProduction } from 'utils';

const useSendUserProperties = (): void => {
  const { funnelId, userId, userEmail, userDomain, userDevice, profileName } = useGetUserData();

  const identifyEvent = useMemo(() => new Identify(), []);

  const [searchParams] = useSearchParams();

  const source = searchParams.get('source');

  useEffect(() => {
    identifyEvent.set('user_environment', isProduction() ? 'prod' : 'dev');
    identifyEvent.set('user_product', 'Lumi');

    if (funnelId) {
      identifyEvent.set('funnel_id', funnelId);
    }

    if (userId) {
      identifyEvent.set('user_id', userId);
    }

    if (userEmail) {
      identifyEvent.set('user_email', userEmail);
    }

    if (userDomain) {
      identifyEvent.set('user_domain', userDomain);
    }

    if (userDevice) {
      identifyEvent.set('user_platform', userDevice === 'iOS' ? 'ios' : 'web');
    }

    if (source) {
      identifyEvent.set('user_source', source);
    }

    if (profileName) {
      identifyEvent.set('user_profile', profileName);
    }

    identify(identifyEvent);
  }, [funnelId, identifyEvent, profileName, source, userDevice, userDomain, userEmail, userId]);
};

export default useSendUserProperties;

import { ISelectedMood } from './mood';
import { HeightValue } from './onboarding';
import { IProgressParts } from './progress';
import {
  QuizAnswer,
  QuizBodyShape,
  QuizClothingStyle,
  QuizFavoriteColor,
  QuizGoal,
  QuizPreferredOutfit,
  QuizSkinTone,
  QuizStyleElevationTarget,
} from './quiz';

export interface IUserProgress {
  currentPart: number;
  currentDay: number;
  timestamp: number;
  isDayCompleted: boolean;
  isDayComplete: boolean;
}

export interface IUserNote {
  title: string;
  text: string;
  timestamp: number;
}

export interface IUserSchema {
  [QuizAnswer.BODY_TYPE]: QuizBodyShape;
  [QuizAnswer.FAVORITE_COLOR]: QuizFavoriteColor | QuizFavoriteColor[];
  [QuizAnswer.SKIN_TONE]: QuizSkinTone;
  [QuizAnswer.GOAL_SETTING]: QuizGoal;
  [QuizAnswer.BUST]: HeightValue;
  [QuizAnswer.WAIST]: HeightValue;
  [QuizAnswer.HIPS]: HeightValue;
  [QuizAnswer.HIGH_HIPS]: HeightValue;
  [QuizAnswer.PREFERRED_OUTFIT]: QuizPreferredOutfit[];
  [QuizAnswer.STYLE_ELEVATION_TARGET]: {
    key: QuizStyleElevationTarget;
    inputValue?: string;
  };
  [QuizAnswer.CLOTHING_STYLE]: QuizClothingStyle;
}

export interface IUser {
  id?: string | null;
  funnelId?: string | null;
  profileName?: string | null;
  photo?: string | null;
  phone?: string | null;
  subscribedAt?: number | null;
  userGoal?: string | null;
  schema?: IUserSchema | null;
  subscriptionId?: string | null;
  status?: UserStatus | string | null;
  userName?: string | null;
  createdAt?: number | null;
  userProgress?: IUserProgress | null;
  email?: string | null;
  password?: string | null;
  guides?: string[] | [] | null;
  isWelcomeEmail?: boolean | null;
  userUltimateWhy?: string | null;
  completeQuiz?: boolean | null;
  domain?: string | null;
  deviceType?: string | null;
  products?: Record<string, string>[] | null;
  notes?: IUserNote[] | null;
  recent?: IProgressParts[] | null;
  selectedMood?: ISelectedMood | null;
  country?: string | null;
  isLifetimePremium?: boolean;
  zendeskJwtToken?: string;
  isUpsellFinished?: boolean;
  isWebOnboardingDone?: boolean;
}

export interface IUserData {
  userData: IUser;
}

export enum UserPermissionStatus {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

interface IUserPaymentsInfo {
  createdAt?: number;
  amount?: number;
  paymentId?: string;
  status?: string;
  paymentType?: string;
}

export interface IUserSubscriptionInfo {
  payments?: Record<string, IUserPaymentsInfo>;
  subscriptionPaymentType?: string;
  subscribedAt?: number;
  cancelCode?: string;
  status?: string;
  email?: string;
  nextChargeAt?: number;
  paymentToken?: string;
  canceledAt?: number;
  userId?: string;
  subscriptionProductId?: string;
  subscriptionProductName?: string;
  id?: string;
  domain?: string;
  price?: number | null;
  trialPeriod?: boolean;
}

export enum EUserSubscriptionType {
  MAIN = 'MAIN',
  ADDITIONAL = 'ADDITIONAL',
}

export enum EUserSubscriptionStatus {
  FREE = 'free',
  TRIAL = 'trial',
  ACTIVE = 'active',
  SUBSCRIBED = 'subscribed',
  CANCELED = 'canceled',
}

export type TUserSubscriptionsInfo = {
  userId: string;
  email: string;
  subscribedAt: number;
  nextChargeAt: number;
  canceledAt: number;
  type: EUserSubscriptionType;
  cancelCode: string;
  status: EUserSubscriptionStatus;
  subscriptionPaymentType: string;
  publicDescription: string;
  payments: unknown;
  subscriptionId: string;
  subscriptionProductId: string;
  price: number;
  trialPrice: number;
  productName: string;
  billingPeriod: {
    primary: string;
    trial: string;
  };
};

export interface IUserPasswordChange {
  currentPassword: string;
  newPassword: string;
  userEmail?: string;
}

export enum UserStatus {
  ACTIVE = 'active',
  PREMIUM = 'premium',
  CANCELED = 'canceled',
  DEFAULT = 'default',
}

export enum UserSubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export enum ReminderValue {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  NO_REMIND = 'NO_REMIND',
}

export enum ReviewStep {
  RATING = 'rating',
  FEEDBACK = 'feedback',
  FEEDBACK_SUCCESS = 'feedbackSuccess',
  TRUSTPILOT = 'trustpilot',
}

export interface IReviewModal {
  isOpen?: boolean;
  step?: ReviewStep;
  rating?: number;
}
